* {
  box-sizing: border-box;
  outline: none;
  margin: 0;
  padding: 0;
}
body {
  position: relative;
  min-height: 100vh;
  overflow-x: hidden;
  background-color: #ecf1f6;
  color: #6f6f6f;
  font-family: 'c', serif;
}
html {
  font-size: 18px;
}
.fullscreen {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  width: 100vw !important;
  height: 100vh !important;
  border-radius: 0 !important;
  z-index: 999 !important;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 100px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 100px;
    border-left: 0;
    border-right: 0;
    background-color: grey;
  }
  &::-webkit-scrollbar-track-piece:end {
    background: transparent;
    margin-bottom: 0;
  }

  &::-webkit-scrollbar-track-piece:start {
    background: transparent;
    margin-top: 0;
  }
}
