@font-face {
   font-family: 'Poppins';
   font-weight: 400;
   src: local('Poppins'), url('../Assets/Fonts/Poppins-Regular.ttf') format('truetype');
 }
@font-face {
   font-family: 'Poppins';
   font-weight: 600;
   src: local('Poppins'), url('../Assets/Fonts/Poppins-Medium.ttf') format('truetype');
 }
@font-face {
   font-family: 'Poppins';
   font-weight: 800;
   src: local('Poppins'), url('../Assets/Fonts/Poppins-Bold.ttf') format('truetype');
 }
@font-face {
   font-family: 'Outfit';
   font-weight: 400;
   src: local('Poppins'), url('../Assets/Fonts/Merriweather-Light.ttf') format('truetype');
 }
@font-face {
   font-family: 'Outfit';
   font-weight: 600;
   src: local('Poppins'), url('../Assets/Fonts/Merriweather-Regular.ttf') format('truetype');
 }
@font-face {
   font-family: 'Outfit';
   font-weight: 800;
   src: local('Poppins'), url('../Assets/Fonts/Merriweather-Bold.ttf') format('truetype');
 }